import PrivacyPolicy from '../components/PrivacyPolicy';
import React, { useState } from 'react';
import Header from '../components/Header';
import Menu from '../components/Menu';
import Footer from '../components/Footer';

function App() {
  return (
    <div>
       <Header />
      <PrivacyPolicy />
       <Footer />
    </div>
  );
}

export default App;
