import React from 'react';
import { Container, Typography } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import bannerImage1 from '../images/banner1.png';
import bannerImage2 from '../images/banner2.png';
import bannerImage3 from '../images/banner3.png';

const bannerImages = [
  { src: bannerImage1, alt: 'Rocket Deals 1' },
  { src: bannerImage2, alt: 'Rocket Deals 2' },
  { src: bannerImage3, alt: 'Rocket Deals 3' },
];

const Banner = () => {
  return (
    <Container style={{ textAlign: 'center' , paddingTop: '15px' }}>
      <Carousel 
        showArrows={true} 
        autoPlay={true} 
        infiniteLoop={true} 
        showThumbs={false}
        interval={3000} // Slide interval in milliseconds
      >
        {bannerImages.map((image, index) => (
          <div key={index}>
            <img src={image.src} alt={image.alt} style={{ width: '100%' }} />
          </div>
        ))}
      </Carousel>
      <Typography variant="h4" style={{ marginTop: '10px' }}>Rocket Deals</Typography>
      <Typography variant="h6">Taking off Now!</Typography>
    </Container>
  );
};

export default Banner;
