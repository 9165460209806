import React from "react";

const Contact = () => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div style={{ width: "50%", padding: "20px" }}>
        <h2>Contact our friendly team</h2>
        <p>Visit us:</p>
        <p>No 85, Ambedkar Street, NH1<br />
          Maraimalainagar, Chennai<br />
          Tamil Nadu 601309</p>
        <p>Contact us:</p>
        <p>WhatsApp : 073050 90539</p>
        <p>Email: shurensangee@gmail.com</p>
      </div>

      <div style={{ width: "50%" }}>
        <iframe
          title="Google Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.6613897283173!2d80.0117750738214!3d12.75536569112415!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52f5861983ad59%3A0xcfa87cb256742e1!2sNo.%2085%2C%20Ambedkar%20St%2C%20NH1%2C%20Maraimalai%20Nagar%2C%20Tamil%20Nadu%20601309!5e0!3m2!1sen!2sin!4v1693495767439!5m2!1sen!2sin"
          width="600"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default Contact;
