import React, { useState } from 'react';
import Header from '../components/Header';
import Menu from '../components/Menu';
import Contact from '../components/Contact';
import Footer from '../components/Footer';

const ContactUs = () => {
    
    
  return (
    <>
      <Header />
      <Contact />
   <Footer />
    </>
  );
};

export default ContactUs;
