import React, { createContext, useContext, useState } from 'react';

const ExampleContext = createContext();

export const useExample = () => useContext(ExampleContext);

export const ExampleProvider = ({ children }) => {
  const [state, setState] = useState("example state");

  const products = [
    { id: 1, img: 'deal1.png', title: 'HOTLINE H310', price: '₹619', originalPrice: '₹1599', discount: '61% off', description: 'Product description here', colors: ['White', 'Blue', 'Black'], additionalImages: ['deal1.png', 'deal1_1.png', 'deal1_2.png'] },
    { id: 2, img: 'deal2.png', title: 'Oceanista Orange Floral Maxi Dress', price: '₹389', originalPrice: '₹1999', discount: '81% off', description: 'Product description here', colors: ['Red', 'Blue', 'Green'], additionalImages: ['deal2.png', 'deal2_1.png', 'deal2_2.png'] },
    // Add more products here
  ];

  return (
    <ExampleContext.Provider value={{ state, setState, products }}>
      {children}
    </ExampleContext.Provider>
  );
};
