import React, { useState } from 'react';
import Header from '../components/Header';
import Menu from '../components/Menu';
import Banner from '../components/Banner';
import Deals from '../components/Deals';
import Footer from '../components/Footer';

const HomePage = () => {
    
    const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);

  const handleSelectCategory = (category, subCategory) => {
    setSelectedCategory(category);
    setSelectedSubCategory(subCategory);
  };
    
    
  return (
    <>
      <Header />
       <Menu onSelectCategory={handleSelectCategory} />
      <Banner />
      <Deals selectedCategory={selectedCategory} selectedSubCategory={selectedSubCategory} />
   <Footer />
    </>
  );
};

export default HomePage;
