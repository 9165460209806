import React from 'react';
import { Container, Grid, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        bgcolor: '#003366', // Dark blue color
        padding: '20px 0',
        marginTop: 'auto',
        borderTop: '1px solid #e0e0e0',
        color: '#ffffff', // White text color for contrast
      }}
    >
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6">About Us</Typography>
            <Typography variant="body2" color="inherit">
              We are a leading online marketplace offering a wide range of products at unbeatable prices.
              Our mission is to provide quality products with excellent customer service.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6">Quick Links</Typography>
            <Typography variant="body2">
              <Link to="/" color="inherit" underline="none">Home</Link><br />
              <Link to="/" color="inherit" underline="none">Products</Link><br />
              <Link to="/contactus" color="inherit" underline="none">Contact Us</Link><br />
             <Link to="/refund" color="inherit" underline="none">Refund </Link><br/>
              <Link to="/privacy" color="inherit" underline="none">Privacy Policy</Link>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6">Follow Us</Typography>
            <Typography variant="body2">
        <p>WhatsApp : 073050 90539</p>
        <p>Email: shurensangee@gmail.com</p>
            </Typography>
          </Grid>
        </Grid>
        <Box textAlign="center" mt={4}>
          <Typography variant="body2" color="inherit">
            © {new Date().getFullYear()} SS Global Mart. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
