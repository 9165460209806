import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ProductDetails from './pages/ProductDetails';
import ContactUs from './pages/ContactUs';
import Refund from './pages/Refund';
import PrivacyPolicy from './pages/PrivacyPolicy';
import { ExampleProvider } from './context/ExampleContext';

/*const products = [
  { id: 1, img: 'deal3.png', title: 'HOTLINE H310', price: '₹619', originalPrice: '₹1599', discount: '61% off', description: 'Product description here', colors: ['White', 'Blue', 'Black'], additionalImages: ['deal1.png', 'deal1_1.png', 'deal1_2.png'] },
  { id: 2, img: 'deal2.png', title: 'Oceanista Orange Floral Maxi Dress', price: '₹389', originalPrice: '₹1999', discount: '81% off', description: 'Product description here', colors: ['Red', 'Blue', 'Green'], additionalImages: ['deal2.png', 'deal2_1.png', 'deal2_2.png'] },
  // Add more products here
      { id: 3, img: 'deal3.png', title: 'Oceanista Orange Floral Maxi Dress', price: '₹389', originalPrice: '₹1999', discount: '81% off', description: 'Product description here', colors: ['Red', 'Blue', 'Green'], additionalImages: ['deal3.png', 'deal2_1.png', 'deal2_2.png'] },
    
         { id: 4, img: 'deal4.png', title: 'Oceanista Orange Floral Maxi Dress', price: '₹389', originalPrice: '₹1999', discount: '81% off', description: 'Product description here', colors: ['Red', 'Blue', 'Green'], additionalImages: ['deal3.png', 'deal4.png', 'deal5.png'] },
    
             { id: 5, img: 'deal5.png', title: 'Oceanista Orange Floral Maxi Dress', price: '₹389', originalPrice: '₹1999', discount: '81% off', description: 'Product description here', colors: ['Red', 'Blue', 'Green'], additionalImages: ['deal3.png', 'deal4.png', 'deal5.png'] },
    
                 { id: 6, img: 'deal6.png', title: 'Oceanista Orange Floral Maxi Dress', price: '₹389', originalPrice: '₹1999', discount: '81% off', description: 'Product description here', colors: ['Red', 'Blue', 'Green'], additionalImages: ['deal3.png', 'deal4.png', 'deal5.png'] },
    
                 { id: 7, img: 'deal7.png', title: 'Oceanista Orange Floral Maxi Dress', price: '₹389', originalPrice: '₹1999', discount: '81% off', description: 'Product description here', colors: ['Red', 'Blue', 'Green'], additionalImages: ['deal3.png', 'deal4.png', 'deal5.png'] },
];*/


const products = [
  {
    id: 1000,
    img: 'lava z70(4949).webp',
    title: 'lava z 70',
    price: '₹4949',
    description: 'Lava Z 70 smartphone',
    colors: ['Black', 'Blue'],
    additionalImages: ['lava z70(4949).webp']
  },
  {
    id: 1001,
    img: 'micromax-bharat-4.webp',
    title: 'micromax bharat4',
    price: '₹4599',
    description: 'Micromax Bharat 4 smartphone',
    colors: ['Black'],
    additionalImages: ['micromax-bharat-4.webp']
  },
  {
    id: 1002,
    img: 'nokia g21.webp',
    title: 'Nokia G21',
    price: '₹3999',
    description: 'Nokia G21 smartphone',
    colors: ['Gray', 'Blue'],
    additionalImages: ['nokia g21.webp']
  },
  {
    id: 1003,
    img: 'ikall k525 pro (4899).webp',
    title: 'I kallK25',
    price: '₹4899',
    description: 'I kall K525 Pro smartphone',
    colors: ['Gold', 'Black'],
    additionalImages: ['ikall k525 pro (4899).webp']
  },
  {
    id: 1005,
    img: 'itel a49.jpg',
    title: 'itel a49',
    price: '₹4999',
    description: 'itel A49 smartphone',
    colors: ['Green', 'Black'],
    additionalImages: ['itel a49.jpg']
  },
  {
    id: 1006,
    img: 'nokia c 20 plus.jpg',
    title: 'Nokia c20',
    price: '₹6199',
    description: 'Nokia C20 Plus smartphone',
    colors: ['Gray', 'Blue'],
    additionalImages: ['nokia c 20 plus.jpg']
  },
  {
    id: 1007,
    img: 'samsung galaxy a03s.jpg',
    title: 'Samsung Galaxy A03s',
    price: '₹7200',
    description: 'Samsung Galaxy A03s smartphone',
    colors: ['Black', 'White'],
    additionalImages: ['samsung galaxy a03s.jpg']
  },
  {
    id: 1008,
    img: 'itel vision2s.jpg',
    title: 'itel vision2s',
    price: '₹4999',
    description: 'itel Vision 2S smartphone',
    colors: ['Green', 'Blue'],
    additionalImages: ['itel vision2s.jpg']
  },
  {
    id: 1009,
    img: 'lava z3pro.jpg',
    title: 'lava z3 pro',
    price: '₹6199',
    description: 'Lava Z3 Pro smartphone',
    colors: ['Black', 'Red'],
    additionalImages: ['lava z3pro.jpg']
  },
  {
    id: 1010,
    img: 'micromax in 2b.jpg',
    title: 'micromax in 2b',
    price: '₹6200',
    description: 'Micromax IN 2B smartphone',
    colors: ['Green', 'Blue'],
    additionalImages: ['micromax in 2b.jpg']
  },
  {
    id: 2000,
    img: 'chudidar1.jpeg',
    title: 'Acuba (1 meter)',
    price: '₹279',
    description: 'Acuba Chudidar material',
    colors: ['Red'],
    additionalImages: ['chudidar1.jpeg']
  },
  {
    id: 2001,
    img: 'chudidar 2.jpeg',
    title: 'chudidar material 2',
    price: '₹120',
    description: 'Chudidar material 2',
    colors: ['Blue'],
    additionalImages: ['chudidar 2.jpeg']
  },
  {
    id: 2002,
    img: 'chudidar 3.jpeg',
    title: 'chudidar material 3',
    price: '₹150',
    description: 'Chudidar material 3',
    colors: ['Pink'],
    additionalImages: ['chudidar 3.jpeg']
  },
  {
    id: 2003,
    img: 'no_image.png',
    title: 'palazo pack of 3',
    price: '₹550',
    description: 'Palazo pack of 3',
    colors: ['Multicolor'],
    additionalImages: ['no_image.png']
  },
  {
    id: 2004,
    img: 'top kurti.webp',
    title: 'top kuruti',
    price: '₹400',
    description: 'Top Kurti',
    colors: ['Yellow', 'Blue'],
    additionalImages: ['top kurti.webp']
  },
  {
    id: 2005,
    img: 'top kurti 2.webp',
    title: 'top kuruti1',
    price: '₹450',
    description: 'Top Kurti 2',
    colors: ['Green'],
    additionalImages: ['top kurti 2.webp']
  },
  {
    id: 2006,
    img: 'jean black.webp',
    title: 'jeans black',
    price: '₹350',
    description: 'Black Jeans',
    colors: ['Black'],
    additionalImages: ['jean black.webp']
  },
  {
    id: 2007,
    img: 'jean cargo.webp',
    title: 'jeans cargo',
    price: '₹450',
    description: 'Jeans Cargo',
    colors: ['Brown'],
    additionalImages: ['jean cargo.webp']
  },
  {
    id: 2008,
    img: 'jean cargo.webp',
    title: 'jeans pack',
    price: '₹1250',
    description: 'Jeans Pack',
    colors: ['Blue', 'Black'],
    additionalImages: ['jean cargo.webp']
  },
  {
    id: 2009,
    img: 'jean pack 4.webp',
    title: 'stack jeans pack',
    price: '₹1500',
    description: 'Stack of Jeans Pack',
    colors: ['Blue'],
    additionalImages: ['jean pack 4.webp']
  },
  {
    id: 2010,
    img: 'jeans-shirt.jpg',
    title: 'jeans shirts',
    price: '₹550',
    description: 'Jeans Shirt',
    colors: ['Blue'],
    additionalImages: ['jeans-shirt.jpg']
  },
  {
    id: 3000,
    img: 'raisins.avif',
    title: 'raisin 500 g',
    price: '₹250',
    description: 'Raisin 500g',
    colors: [''],
    additionalImages: ['raisins.avif']
  },
  {
    id: 3001,
    img: 'black-raisins.avif',
    title: 'black raisins 500g',
    price: '₹300',
    description: 'Black Raisins 500g',
    colors: [''],
    additionalImages: ['black-raisins.avif']
  },
  {
    id: 3002,
    img: 'almonds.jpg',
    title: 'almonds 500g',
    price: '₹450',
    description: 'Almonds 500g',
    colors: [''],
    additionalImages: ['almonds.jpg']
  },
  {
    id: 3003,
    img: 'cashew.jpg',
    title: 'cashew 500g',
    price: '₹400',
    description: 'Cashew 500g',
    colors: [''],
    additionalImages: ['cashew.jpg']
  },
  {
    id: 3004,
    img: 'kiwi-dried.jpg',
    title: 'dry kiwi 250g',
    price: '₹500',
    description: 'Dried Kiwi 250g',
    colors: [''],
    additionalImages: ['kiwi-dried.jpg']
  },
  {
    id: 3005,
    img: 'fried-assorted mix.avif',
    title: 'fried dry assorted 250g',
    price: '₹350',
    description: 'Fried Dry Assorted 250g',
    colors: [''],
    additionalImages: ['fried-assorted mix.avif']
  },
  {
    id: 3006,
    img: 'dates.avif',
    title: 'dates 250 g',
    price: '₹300',
    description: 'Dates 250g',
    colors: [''],
    additionalImages: ['dates.avif']
  },
  {
    id: 3007,
    img: 'arabian dates.jpg',
    title: 'arabian dates 250 g',
    price: '₹350',
    description: 'Arabian Dates 250g',
    colors: [''],
    additionalImages: ['arabian dates.jpg']
  },
  {
    id: 3008,
    img: 'raw-walnuts.jpg',
    title: 'raw walnut 250 g',
    price: '₹350',
    description: 'Raw Walnut 250g',
    colors: [''],
    additionalImages: ['raw-walnuts.jpg']
  },
  {
    id: 3009,
    img: 'dried-apricots-raisin-nuts.jpg',
    title: 'dried-apricots-raisin-nuts 250g',
    price: '₹400',
    description: 'Dried Apricots Raisins Nuts 250g',
    colors: [''],
    additionalImages: ['dried-apricots-raisin-nuts.jpg']
  },
  {
    id: 3010,
    img: 'dried-figs.jpg',
    title: 'dry fig 250g',
    price: '₹300',
    description: 'Dry Figs 250g',
    colors: [''],
    additionalImages: ['dried-figs.jpg']
  }
];



const deals = {
  Electronics: {
    Laptops: [
      { id: 1, img: 'deal1.png', title: 'HOTLINE H310', price: '₹619', discount: '61% off' },
      { id: 2, img: 'deal2.png', title: 'Laptop XYZ', price: '₹49999', discount: '20% off' },
    ],
    Mobiles: [
      { id: 3, img: 'deal3.png', title: 'Smartphone XYZ', price: '₹8999', discount: '45% off' },
      { id: 4, img: 'deal4.png', title: 'Smartphone ABC', price: '₹7999', discount: '35% off' },
    ],
    Cameras: [
      { id: 8, img: 'deal8.png', title: 'Camera 123', price: '₹25999', discount: '10% off' },
    ],
  },
  Clothing: {
    Men: [
      { id: 5, img: 'deal5.png', title: 'Men\'s Jacket', price: '₹1299', discount: '30% off' },
    ],
    Women: [
      { id: 6, img: 'deal6.png', title: 'Oceanista Orange Floral Maxi Dress', price: '₹389', discount: '81% off' },
    ],
    Kids: [
      { id: 9, img: 'deal9.png', title: 'Kids T-Shirt', price: '₹299', discount: '50% off' },
    ]
  },
  HomeAndKitchen: {
    Appliances: [
      { id: 7, img: 'deal7.png', title: 'Non-stick Cookware', price: '₹799', discount: '50% off' },
    ],
  },
};

const App = () => {
  return (
    <ExampleProvider>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/product/:productId" element={<ProductDetails products={products} />} />
          <Route path="/contactus" element={<ContactUs/>} />
          <Route path="/refund" element={<Refund/>} />
          <Route path="/privacy" element={<PrivacyPolicy/>} />
        </Routes>
      </Router>
    </ExampleProvider>
    
  );
};

export default App;
