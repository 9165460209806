import React from 'react';
import './PrivacyPolicy.css'; // Import a CSS file for styling if needed

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      <p>Effective Date: September 2024</p>
      
      <h2>1. Information We Collect</h2>
      <p>
        We may collect the following types of information when you interact with our website or services:
      </p>
      <ul>
        <li><strong>Personal Information</strong>: Your name, contact details (such as phone number and email), and address when you communicate with us or register for our services.</li>
        <li><strong>Usage Information</strong>: Information about how you use our website, including IP address, browser type, pages visited, and the duration of your visit.</li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <p>
        We use your information for the following purposes:
      </p>
      <ul>
        <li><strong>To Provide Services</strong>: We use your personal information to respond to inquiries, fulfill requests, and provide our services.</li>
        <li><strong>To Improve Our Services</strong>: Usage information helps us understand how our website is used and improve user experience.</li>
        <li><strong>Marketing and Communication</strong>: With your consent, we may send promotional emails, newsletters, or contact you via phone regarding our services.</li>
      </ul>

      <h2>3. Data Sharing and Disclosure</h2>
      <p>
        SS Global does not sell, rent, or trade your personal information with third parties. We may share your data only in the following circumstances:
      </p>
      <ul>
        <li><strong>Service Providers</strong>: We may share your data with trusted third-party service providers who assist in delivering our services. These providers are obligated to protect your information.</li>
        <li><strong>Legal Requirements</strong>: We may disclose your data to comply with legal obligations, protect our rights, or respond to legal requests from authorities.</li>
      </ul>

      <h2>4. Data Security</h2>
      <p>
        We take data security seriously. We implement reasonable measures to protect your personal information from unauthorized access, disclosure, or misuse. However, no method of transmission over the internet is completely secure, and we cannot guarantee absolute security.
      </p>

      <h2>5. Your Rights</h2>
      <p>You have the following rights regarding your personal information:</p>
      <ul>
        <li><strong>Access</strong>: You can request access to the personal information we hold about you.</li>
        <li><strong>Correction</strong>: You can request corrections to any inaccuracies in your personal information.</li>
        <li><strong>Deletion</strong>: You can request that we delete your personal information, subject to legal or contractual obligations.</li>
      </ul>
      <p>To exercise these rights, please contact us at <a href="mailto:shurensangee@gmail.com">shurensangee@gmail.com</a>.</p>

      <h2>6. Cookies and Tracking Technologies</h2>
      <p>
        We may use cookies and similar technologies to improve our website's functionality and analyze usage patterns. You can adjust your browser settings to refuse cookies, though this may affect your experience on our site.
      </p>

      <h2>7. Third-Party Links</h2>
      <p>
        Our website may contain links to third-party websites. This Privacy Policy applies only to SS Global’s website. We are not responsible for the privacy practices or content of third-party websites.
      </p>

      <h2>8. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. Changes will be posted on this page with the revised effective date. We encourage you to review this policy periodically.
      </p>

      <h2>9. Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy or your personal information, please contact us at:
      </p>
      <address>
        <strong>SS Global</strong><br />
        No 85, Ambedkar Street, NH1<br />
        Maraimalainagar, Chennai<br />
        Tamil Nadu 601309<br />
        Email: <a href="mailto:shurensangee@gmail.com">shurensangee@gmail.com</a><br />
        Phone: 073050 90539
      </address>
    </div>
  );
};

export default PrivacyPolicy;
