import React, { useState } from 'react';
import { AppBar, Toolbar, Button, IconButton, Drawer, MenuItem, Menu, useMediaQuery, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const MenuComponent = ({ onSelectCategory }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const isMobile = useMediaQuery('(max-width:768px)');

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleClick = (event, category) => {
    setAnchorEl(event.currentTarget);
    setOpenSubMenu(category);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenSubMenu(null);
  };

  const mobileMenu = (
    <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer}>
      <div onClick={toggleDrawer} onKeyDown={toggleDrawer}>
        <MenuItem onClick={() => onSelectCategory(null, null)}>All</MenuItem>
        <MenuItem onClick={(e) => handleClick(e, 'electronics')}>Electronics</MenuItem>
        <MenuItem onClick={(e) => handleClick(e, 'clothing')}>Clothing</MenuItem>
        <MenuItem onClick={(e) => handleClick(e, 'homekitchen')}>Home & Kitchen</MenuItem>
        {['Appliances', 'Sports & More', 'Essentials', 'Offers', 'Global Shopping'].map((item) => (
          <MenuItem key={item} onClick={() => onSelectCategory(item.replace(' ', ''), null)}>{item}</MenuItem>
        ))}
      </div>
    </Drawer>
  );

  const desktopMenu = (
    <Toolbar>
      <Button color="inherit" onClick={() => onSelectCategory(null, null)}>All</Button>
      <Button color="inherit" onClick={(e) => handleClick(e, 'electronics')}>Electronics</Button>
      <Button color="inherit" onClick={(e) => handleClick(e, 'clothing')}>Clothing</Button>
      <Button color="inherit" onClick={(e) => handleClick(e, 'homekitchen')}>Home & Kitchen</Button>
      {['Appliances', 'Sports & More', 'Essentials', 'Offers', 'Global Shopping'].map((item) => (
        <Button key={item} color="inherit" onClick={() => onSelectCategory(item.replace(' ', ''), null)}>{item}</Button>
      ))}
    </Toolbar>
  );

  return (
    <AppBar position="static" color="default">
      {isMobile ? (
        <>
          <Toolbar>
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer}>
              <MenuIcon />
            </IconButton>
          </Toolbar>
          {mobileMenu}
        </>
      ) : (
        desktopMenu
      )}

      {/* SubMenus */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl) && openSubMenu === 'electronics'}
        onClose={handleClose}
      >
        <MenuItem onClick={() => { onSelectCategory('Electronics', 'Mobiles'); handleClose(); }}>Mobiles</MenuItem>
        <MenuItem onClick={() => { onSelectCategory('Electronics', 'Laptops'); handleClose(); }}>Laptops</MenuItem>
        <MenuItem onClick={() => { onSelectCategory('Electronics', 'Cameras'); handleClose(); }}>Cameras</MenuItem>
      </Menu>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl) && openSubMenu === 'clothing'}
        onClose={handleClose}
      >
        <MenuItem onClick={() => { onSelectCategory('Clothing', 'Men'); handleClose(); }}>Men</MenuItem>
        <MenuItem onClick={() => { onSelectCategory('Clothing', 'Women'); handleClose(); }}>Women</MenuItem>
        <MenuItem onClick={() => { onSelectCategory('Clothing', 'Kids'); handleClose(); }}>Kids</MenuItem>
      </Menu>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl) && openSubMenu === 'homekitchen'}
        onClose={handleClose}
      >
        <MenuItem onClick={() => { onSelectCategory('HomeAndKitchen', 'Grocery'); handleClose(); }}>Grocery</MenuItem>
      </Menu>
    </AppBar>
  );
};

export default MenuComponent;
